import React from "react";
import "./Header.css";
import ReactPlayer from "react-player";

function Header() {
  const handleVideoEnd = () => {
    if (window.fbq) {
      window.fbq('trackCustom', 'VideoComplete', {
        video_url: "https://www.youtube.com/watch?v=hXtVRGo6XkU"
      });
      console.log("Evento VideoComplete disparado");
    }
  };

  return (
    <header className="header">
      <h1 data-aos="fade-up" className="header-title">DOMINUS</h1>
      <div data-aos="fade-up" className="header-video">
        <ReactPlayer
          url="https://www.youtube.com/watch?v=hXtVRGo6XkU"
          playing={true}  // Autoplay (correto)
          muted={true}    // Mudo inicialmente (necessário para autoplay)
          volume={0.3}    // Define o volume para 30%
          controls={false} // Remove os controles
          width="1280px"     // Garante que o player ocupe toda a largura da tela
          height="720px"    // Garante que o player ocupe toda a altura da tela
          config={{
            youtube: {
              playerVars: {
                autoplay: 1,  // Autoplay (também configurado via URL)
                controls: 1,  // Remove controles do player
                modestbranding: 1, // Menos branding (logo do YouTube)
                showinfo: 0,  // Remove informações sobre o vídeo
                rel: 0,       // Remove vídeos relacionados ao final
              }
            }
          }}
          onEnded={handleVideoEnd}   // Dispara quando o vídeo for completado
        />
      </div>
    </header>
  );
}

export default Header;
