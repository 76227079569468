/* global fbq */
import React, { useEffect } from "react";
import './App.css';
import Header from "./components/Header.js";
import Card from "./components/Card.js";
import CoachSection from "./components/CoachSection";
import DominusInfo from "./components/DominusInfo.js";
import PromoBanner from "./components/PromoBanner.js";
import Faqs from "./components/Faqs.js";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Footer from "./components/Footer.js";
import Reviews from "./components/Review.js";

// Importação do AOS
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    // Inicializando o AOS (Animações)
    AOS.init({
      duration: 1200, // Duração da animação em milissegundos
      offset: 200,    // Distância de ativação
      easing: "ease-in-out", // Tipo de animação
      once: true,     // Animação acontece apenas uma vez
    });

    // Inicializando o Facebook Pixel dentro do useEffect
    (function(f,b,e,v,n,t,s){
      if(f.fbq) return;
      n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq) f._fbq = n;
      n.push=n;
      n.loaded=!0;
      n.version="2.0";
      n.queue=[];
      t=b.createElement(e);t.async=!0;
      t.src=v;
      s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)
    })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

    fbq("init", "1051838590080361"); // Seu Pixel ID
    fbq("track", "PageView"); // Rastreando visualizações de página

  }, []);

  return (
    <>
      <Header />
      <div>
        {/* Adicionando animações aos componentes */}
        <div data-aos="fade-up">
          <Card />
        </div>
        <div data-aos="zoom-in">
          <PromoBanner />
        </div>
        <div data-aos="fade-up">
          <CoachSection />
        </div>
        <div data-aos="fade-up">
          <Reviews />
        </div>
        <div data-aos="zoom-in">
          <DominusInfo />
        </div>
        <div data-aos="fade-up">
          <Faqs />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
