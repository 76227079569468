import React, { useEffect } from "react";
import "./Card.css"; // Arquivo CSS para estilização
import Icon1 from '../assets/logo-dominus.png';
import Icon2 from '../assets/coach-icon.png';
import Icon3 from '../assets/gift-icon.png';

const Card = ({ iconSrc, title, description, buttonText, link }) => {
  // Função para redirecionar o usuário ao clicar no botão
  const handleButtonClick = (event) => {
    event.preventDefault(); // Previne o comportamento padrão do link
    window.open(link, "_blank", "noopener,noreferrer");  // Abre o link em uma nova aba de forma segura
  };

  return (
    <div className="card">
      <div className="card-icon">
        <img src={iconSrc} alt={title} />
      </div>
      <h3 className="card-title">{title}</h3>
      {/* Permite HTML no description */}
      <p
        className="card-description"
        dangerouslySetInnerHTML={{ __html: description }}
      ></p>
      <a
        href={link}
        target="_blank" // Abre em outra aba
        rel="noopener noreferrer" // Boa prática de segurança
        className="card-button"
        onClick={handleButtonClick} // Adiciona o rastreamento ao clique
      >
        {buttonText}
      </a>
    </div>
  );
};

const CardsContainer = () => {
  const cardsData = [
    {
      iconSrc: Icon1, 
      title: "CURSO DOMINUS",
      description:
        "Curso COMPLETO de VALORANT, fundamentos básicos até o mais avançado.<br/><br/>" +
        "Bônus: Além do conteúdo principal, você receberá 4 aulas extras aprofundadas sobre as funções do jogo: Duelista, Iniciador, Controlador e Sentinela.<br/><br/>" +
        "<div class='card-price'>" +
        "Oferta especial de: <span class='old-price'>R$ 178,00</span>" +
        " por <span class='new-price'>R$ 87,00</span>" +
        "</div>",
      buttonText: "SEJA DOMINUS",
      link: "https://pay.kirvano.com/9f9f0d8b-4ed8-46c1-a707-158ef97d2507",
    },
    {
      iconSrc: Icon2,
      title: "AULAS PERSONALIZADAS",
      description:
        "Receba uma análise detalhada das suas partidas feita por nossa equipe de profissionais e ex-profissionais do cenário competitivo.<br/>" +
        "Nossa equipe inclui coaches experientes que atuam em campeonatos oficiais da Riot Games, como o VCT Game Changers. Tenha a oportunidade de melhorar sua gameplay com orientações práticas e personalizadas para elevar seu nível de jogo!",
      buttonText: "QUERO UMA AULA",
      link: "https://pay.kirvano.com/3975f6e6-9a91-48c3-979f-bce5aa3797eb",
    },
    {
      iconSrc: Icon3,
      title: "PREMIAÇÕES DOMINUS",
      description:
        "Ao se tornar aluno da Dominus Academy.<br/><br/>" +
        "Você terá acesso a sorteios mensais exclusivos que dão dinheiro ou um periférico a sua escolha e também poderá participar de nossos campeonatos trimestrais, abertos para todos os elos.<br/>" +
        "Esses torneios oferecem premiações incríveis, incluindo dinheiro e VP!",
      buttonText: "PARTICIPE AGORA",
      link: "https://pay.kirvano.com/9f9f0d8b-4ed8-46c1-a707-158ef97d2507",
    },
  ];

  useEffect(() => {
    // Aqui já não é mais necessário inicializar o Pixel, pois ele é carregado no index.html
  }, []);

  return (
    <div data-aos="fade" className="cards-container">
      {cardsData.map((card, index) => (
        <Card
          key={index}
          iconSrc={card.iconSrc}
          title={card.title}
          description={card.description}
          buttonText={card.buttonText}
          link={card.link}
        />
      ))}
    </div>
  );
};

export default CardsContainer;
